<template lang="pug">
om-modal.new-campaign-sms-prompt-modal.centered-modal(
  name="new-campaign-sms-prompt"
  :class="{ loading: createCampaignLoading }"
  :width="500"
  :clickToClose="false"
  @beforeOpen="beforeOpen"
)
  template(slot="modal-header")
    .row
      .col
        om-heading(h5) {{ $t('newCampaignSMSPrompt.title') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('new-campaign-sms-prompt')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    BoxSelect(:items="boxSelectItems" @select="createCampaign")
</template>
<script>
  import { get as _get } from 'lodash-es';
  import { mapMutations } from 'vuex';
  import { getAccountIdFromCookie } from '@/util';
  import { track } from '@/services/xray';
  import CREATE_CAMPAIGN from '@/graphql/CreateCampaign.gql';
  import { createCampaignAndRedirectToEditor } from '@/router/utils';

  export default {
    components: {
      BoxSelect: () => import('@/components/Elements/BoxSelect/List.vue'),
    },
    data: () => ({
      campaignCreateInput: null,
      sourceUrl: '',
      createCampaignLoading: false,
    }),

    computed: {
      boxSelectItems() {
        return [
          {
            value: 'onlyEmail',
            image: require(`@/assets/admin/svg/newCampaignSMSPrompt/email.svg`),
            title: `newCampaignSMSPrompt.email`,
          },
          {
            value: 'withSMS',
            image: require(`@/assets/admin/svg/newCampaignSMSPrompt/sms.svg`),
            title: `newCampaignSMSPrompt.emailAndSMS`,
          },
        ];
      },
    },
    methods: {
      ...mapMutations(['showAdminLoader', 'setLoadingWithoutSidebar']),
      beforeOpen(event) {
        const { isWizard, theme, sourceUrl, campaignCreateInput } = event.params;
        this.isWizard = isWizard;
        this.theme = theme;
        this.sourceUrl = sourceUrl;
        this.campaignCreateInput = campaignCreateInput;
        this.createCampaignLoading = false;
        track('SMSPromptModalOpen');
      },
      async createCampaign(type) {
        track('SMSPromptOptionClicked', { type });
        if (this.createCampaignLoading) return;

        this.setLoadingWithoutSidebar(true);
        this.showAdminLoader(true);
        this.setLoadingWithoutSidebar(false);
        this.createCampaignLoading = true;

        const removeSMSPage = type === 'onlyEmail';

        if (this.isWizard) {
          await createCampaignAndRedirectToEditor('wizard', { theme: this.theme, removeSMSPage });
          return;
        }

        const r = await this.$apollo.mutate({
          mutation: CREATE_CAMPAIGN,
          variables: {
            input: { ...this.campaignCreateInput, removeSMSPage },
          },
        });

        const campaignId = _get(r, 'data.createCampaign.id');
        const variantId = _get(r, 'data.createCampaign.variants.0._id');

        if (!campaignId || !variantId) {
          throw new Error('Failed to create campaign');
        }

        window.location = `/${getAccountIdFromCookie()}/variant/${campaignId}/${variantId}/edit/new${
          this.sourceUrl
        }`;
      },
    },
  };
</script>

<style lang="sass">
  .new-campaign-sms-prompt-modal
    &.v--modal-overlay.loading
      z-index: 10 !important
    .brand-modal-header
      text-align: center
      .heading-5
        font-size: 20px !important
    .box-select-item-title
      margin-bottom: 0
      font-size: 16px
      font-weight: 500
    .box-select-item
      padding: 20px 0
    .brand-modal-body
      padding: 0 2.857rem
</style>
